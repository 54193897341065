import "./CandidaturesSingleSubmitConfirmation.scss"

import {useEffect} from "react"

export default function CandidaturesSingleSubmitConfirmation(
  {
    onClose,
    onSubmitCandidature,
  }
) {
  useEffect(() => {
    document.querySelector("body").style.overflow = "hidden"

    return () => {
      document.querySelector("body").style.overflow = "auto"
    }
  }, [])

  return (
    <div className={"CandidaturesSingleSubmitConfirmation"}>
      <div className="CandidaturesSingleSubmitConfirmation-content">
        <div className="CandidaturesSingleSubmitConfirmation-body">
          <h2 className={"tag-h2"}>Soumettre ma candidature</h2>
          <p className={"tag-p"}>
            Votre candidature sera envoyée au service des admissions pour être examinée. Ils vous contacteront pour
            d'éventuelles corrections ou validation.
          </p>
          <p className={"tag-p"}>
            Veuillez noter qu'aucune modification ne pourra être apportée pendant l'examen de votre candidature.
          </p>
        </div>
        <div className="CandidaturesSingleSubmitConfirmation-footer">
          <button
            aria-label={"Continuer à modifier"}
            className={"CandidaturesSingleSubmitConfirmation-button tag-button is-outline"}
            onClick={onClose}
            title={"Continuer à modifier"}
          >
            Annuler
          </button>
          <button
            aria-label={"Soumettre ma candidature"}
            className={"CandidaturesSingleSubmitConfirmation-button tag-button"}
            onClick={onSubmitCandidature}
            title={"Soumettre ma candidature"}
          >
            Confirmer
          </button>
        </div>
      </div>
    </div>
  )
}