import "./CandidaturesSingle.scss"

import {useEffect, useState} from "react"
import {useLoaderData} from "react-router-dom"

import {Etape, Statut} from "./constants"
import CandidaturesSingleLayout from "./Layout/CandidaturesSingleLayout"
import CandidaturesSingleEtatCivil from "./EtatCivil/CandidaturesSingleEtatCivil"
import CandidaturesSingleTuteurs from "./Tuteurs/CandidaturesSingleTuteurs"
import {candidatureAllEtapesAreDone, candidatureEtapeIsMade, candidatureSortedEtapes} from "./helpers"
import CandidaturesSingleParcoursScolaires from "./ParcoursScolaires/CandidaturesSingleParcoursScolaires"
import CandidaturesSingleExperiencesProfessionnelles from "./ExperiencesProfessionnelles/CandidaturesSingleExperiencesProfessionnelles"
import CandidaturesSingleMetiers from "./Metiers/CandidaturesSingleMetiers"
import CandidaturesSingleCompetences from "./Competences/CandidaturesSingleCompetences"
import CandidaturesSingleProjetProfessionnel from "./ProjetProfessionnel/CandidaturesSingleProjetProfessionnel"
import CandidaturesSingleAlternance from "./Alternance/CandidaturesSingleAlternance"
import CandidaturesSingleEcole from "./Ecole/CandidaturesSingleEcole"
import CandidaturesSingleSavoirEtreProfessionnel from "./SavoirEtreProfessionnel/CandidaturesSingleSavoirEtreProfessionnel"
import CandidaturesSinglePiecesJointes from "./PiecesJointes/CandidaturesSinglePiecesJointes"
import CandidaturesSingleSubmitConfirmation from "./SubmitConfirmation/CandidaturesSingleSubmitConfirmation"
import CandidaturesSinglePostSoumission from "./PostSoumission/CandidaturesSinglePostSoumission"
import {api} from "../services"

export default function CandidaturesSingle() {
  const data = useLoaderData()
  const [candidature, setCandidature] = useState(data)
  const [sortedEtapes, setSortedEtapes] = useState([])
  const [currentEtape, setCurrentEtape] = useState(null)
  const [showSubmitConfirmationModal, setShowSubmitConfirmationModal] = useState(false)
  const [showSuccessFileUploadNotification, setShowSuccessFileUploadNotification] = useState(false)

  useEffect(() => {
    setSortedEtapes(
      candidatureSortedEtapes(candidature)
    )
  }, [])

  useEffect(() => {
    for (const etape of sortedEtapes) {
      if (!candidatureEtapeIsMade(candidature, etape)) {
        setCurrentEtape(etape)
        return
      }
    }

    if (candidature.Statut === Statut.EnCours && candidatureAllEtapesAreDone(candidature)) {
      setCurrentEtape(sortedEtapes[sortedEtapes.length - 1])
      return
    }

    if (candidature.Statut === Statut.ACorriger) {
      setCurrentEtape(sortedEtapes[0])
    }
  }, [sortedEtapes])

  useEffect(() => {
    if (showSuccessFileUploadNotification) {
      setTimeout(() => {
        setShowSuccessFileUploadNotification(false)
      }, 2000)
    }
  }, [showSuccessFileUploadNotification])

  const updateEtatCivil = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/etat-civil`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateTuteurs = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/tuteurs`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateParcoursScolaires = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/parcours-scolaires`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateExperiencesProfessionnelles = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/experiences-professionnelles`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateMetiers = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/metiers`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateCompetences = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/competences`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateProjetProfessionnel = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/projet-professionnel`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateAlternance = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/alternance`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateEcole = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/ecole`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updateSavoirEtreProfessionnel = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/savoir-etre-professionnel`, data)
      .then(() => refreshCandidature())
      .then(() => moveToNextEtape())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const updatePiecesJointes = (data) => {
    api("PATCH", `/candidatures/${candidature.ID}/pieces-jointes`, data)
      .then(() => {
        setShowSuccessFileUploadNotification(true)
      })
      .then(() => refreshCandidature())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const submitCandidature = () => {
    api("POST", `/candidatures/${candidature.ID}/soumettre`, data)
      .then(() => {
        setShowSubmitConfirmationModal(false)
        setCurrentEtape(null)
      })
      .then(() => refreshCandidature())
      .catch(() => {
        alert("Un problème est survenu. Veuillez réessayer.")
      })
  }

  const refreshCandidature = () => {
    return new Promise((resolve, reject) => {
      api("GET", `/candidatures/${candidature.ID}`)
        .then(candidature => {
          setCandidature(candidature)
        })
        .then(resolve)
        .catch(reject)
    })
  }

  const moveToNextEtape = () => {
    if (candidatureAllEtapesAreDone(candidature) && currentEtape === sortedEtapes.slice(-1)[0]) {
      setShowSubmitConfirmationModal(true)
      return
    }

    if (currentEtape === sortedEtapes.slice(-1)[0]) {
      return
    }

    const currentEtapeIndex = sortedEtapes.indexOf(currentEtape)
    setCurrentEtape(sortedEtapes[currentEtapeIndex + 1])
  }

  return (
    <>
      {(
        candidature.Statut === Statut.AValider ||
        candidature.Statut === Statut.Stopper ||
        candidature.Statut === Statut.Valider
      ) ? (
        <CandidaturesSinglePostSoumission candidature={candidature}/>
      ) : currentEtape ? (
        <CandidaturesSingleLayout
          candidature={candidature}
          currentEtape={currentEtape}
          onEtapeChange={setCurrentEtape}
        >
          {currentEtape === Etape.EtatCivil && (
            <CandidaturesSingleEtatCivil EtatCivil={candidature.EtatCivil} onEtatCivilChange={updateEtatCivil}/>
          )}
          {currentEtape === Etape.Tuteurs && (
            <CandidaturesSingleTuteurs
              EtatCivil={candidature.EtatCivil}
              Tuteurs={candidature.Tuteurs}
              onTuteursChange={updateTuteurs}
            />
          )}
          {currentEtape === Etape.ParcoursScolaires && (
            <CandidaturesSingleParcoursScolaires
              ParcoursScolaires={candidature.ParcoursScolaires}
              onParcoursScolairesChange={updateParcoursScolaires}
            />
          )}
          {currentEtape === Etape.ExperiencesProfessionnelles && (
            <CandidaturesSingleExperiencesProfessionnelles
              ExperiencesProfessionnelles={candidature.ExperiencesProfessionnelles}
              onExperiencesProfessionnellesChange={updateExperiencesProfessionnelles}
            />
          )}
          {currentEtape === Etape.Metiers && (
            <CandidaturesSingleMetiers Metiers={candidature.Metiers} onMetiersChange={updateMetiers}/>
          )}
          {currentEtape === Etape.Competences && (
            <CandidaturesSingleCompetences
              Competences={candidature.Competences}
              onCompetencesChange={updateCompetences}
            />
          )}
          {currentEtape === Etape.ProjetProfessionnel && (
            <CandidaturesSingleProjetProfessionnel
              ProjetProfessionnel={candidature.ProjetProfessionnel}
              onProjetProfessionnel={updateProjetProfessionnel}
            />
          )}
          {currentEtape === Etape.Alternance && (
            <CandidaturesSingleAlternance
              Alternance={candidature.Alternance}
              onAlternanceChange={updateAlternance}
            />
          )}
          {currentEtape === Etape.Ecole && (
            <CandidaturesSingleEcole Ecole={candidature.Ecole} onEcoleChange={updateEcole}/>
          )}
          {currentEtape === Etape.SavoirEtreProfessionnel && (
            <CandidaturesSingleSavoirEtreProfessionnel
              onSavoirEtreProfessionnelChange={updateSavoirEtreProfessionnel}
              SavoirEtreProfessionnel={candidature.SavoirEtreProfessionnel}
            />
          )}
          {currentEtape === Etape.PiecesJointes && (
            <CandidaturesSinglePiecesJointes
              enableSoumettreUneCandidatureButton={candidatureAllEtapesAreDone(candidature)}
              onPieceJointeCategorieSubmit={updatePiecesJointes}
              onValidate={moveToNextEtape}
              PiecesJointes={candidature.PiecesJointes}
              PiecesJointesEtapes={candidature.PiecesJointesEtapes}
            />
          )}

          {showSuccessFileUploadNotification && (
            <div className={"CandidaturesSingle-notification"}>
              <p className="tag-p is-extra is-s">Les documents ont été téléchargés avec succès.</p>
            </div>
          )}

          {showSubmitConfirmationModal && (
            <CandidaturesSingleSubmitConfirmation
              onClose={() => setShowSubmitConfirmationModal(false)}
              onSubmitCandidature={submitCandidature}
            />
          )}
        </CandidaturesSingleLayout>
      ) : ""}
    </>
  )
}