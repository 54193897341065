import {useState} from "react"

import {SituationActuelle as SituationActuelleEnum} from "../constants"
import FormInputRadio from "../../../Form/InputRadio/FormInputRadio"
import FormInputRadioGroup from "../../../Form/InputRadio/Group/FormInputRadioGroup"
import FormInputText from "../../../Form/FormInputText"
import FormTooltip from "../../../Form/Tooltip/FormTooltip"
import FormFieldset from "../../../Form/Fieldset/FormFieldset"
import FormInputTel from "../../../Form/InputTel/FormInputTel"
import FormInputEmail from "../../../Form/FormInputEmail"
import FormInputDate from "../../../Form/InputDate/FormInputDate"
import FormSelectCountry from "../../../Form/FormSelectCountry"
import {scrollToFirstError} from "../../helpers"

export default function CandidaturesSingleEtatCivil({EtatCivil, onEtatCivilChange}) {
  const [Adresse, setAdresse] = useState(EtatCivil.Adresse.Adresse)
  const [Appartement, setAppartement] = useState(EtatCivil.Adresse.Appartement)
  const [Batiment, setBatiment] = useState(EtatCivil.Adresse.Batiment)
  const [Civilite, setCivilite] = useState(EtatCivil.Civilite)
  const [CodePostal, setCodePostal] = useState(EtatCivil.Adresse.CodePostal)
  const [DateDeNaissance, setDateDeNaissance] = useState(EtatCivil.DateDeNaissance)
  const [DepartementDeNaissance, setDepartementDeNaissance] = useState(EtatCivil.DepartementDeNaissance)
  const [Email, setEmail] = useState(EtatCivil.Email)
  const [IdentifiantPoleEmploi, setIdentifiantPoleEmploi] = useState(EtatCivil.IdentifiantPoleEmploi)
  const [INE, setINE] = useState(EtatCivil.INE)
  const [Nationnalite, setNationnalite] = useState(EtatCivil.Nationnalite)
  const [NomDeNaissance, setNomDeNaissance] = useState(EtatCivil.NomDeNaissance)
  const [NomDusage, setNomDusage] = useState(EtatCivil.NomDusage)
  const [Pays, setPays] = useState(EtatCivil.Adresse.Pays)
  const [PaysDeNaissance, setPaysDeNaissance] = useState(EtatCivil.PaysDeNaissance)
  const [Portable, setPortable] = useState(EtatCivil.Portable)
  const [Prenoms, setPrenoms] = useState(EtatCivil.Prenoms)
  const [Residence, setResidence] = useState(EtatCivil.Residence)
  const [SituationActuelle, setSituationActuelle] = useState(EtatCivil.SituationActuelle)
  const [Ville, setVille] = useState(EtatCivil.Adresse.Ville)
  const [VilleDeNaissance, setVilleDeNaissance] = useState(EtatCivil.VilleDeNaissance)
  const [AutreSituationActuelle, setAutreSituationActuelle] = useState(EtatCivil.AutreSituationActuelle)


  const submit = (event) => {
    event.preventDefault()

    if(!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onEtatCivilChange({
      Adresse: {
        Adresse,
        CodePostal,
        Pays,
        Ville,
        Appartement,
        Batiment,
        Residence,
      },
      AutreSituationActuelle,
      Civilite,
      DateDeNaissance,
      DepartementDeNaissance,
      Email,
      IdentifiantPoleEmploi,
      INE,
      Nationnalite,
      NomDeNaissance,
      NomDusage,
      PaysDeNaissance,
      Prenoms,
      Portable,
      SituationActuelle,
      VilleDeNaissance,
    })
  }

  return (
    <form className={"CandidaturesSingleEtatCivil"} onSubmit={submit} noValidate>
      <div className="helpers-formBody">
        <FormInputRadioGroup
          description={"Tel qu’indiqué sur votre carte d’identité officielle."}
          label={"Civilité"}
          required={true}
        >
          <FormInputRadio
            checked={Civilite === "M"}
            id={"M"}
            label={"M."}
            name={"Civilite"}
            onChange={(e) => setCivilite(e.target.value)}
            required={true}
            value={"M"}
          />
          <FormInputRadio
            checked={Civilite === "Mme"}
            id={"Mme"}
            label={"Mme."}
            name={"Civilite"}
            onChange={(e) => setCivilite(e.target.value)}
            required={true}
            value={"Mme"}
          />
        </FormInputRadioGroup>
        <FormTooltip
          content={
            <a
              href={"https://etudiant.lefigaro.fr/vos-etudes/vie-etudiante/33954-numero-ine-qu-est-ce-que-c-est-et-ou-le-trouver/"}
              target={"_blank"}
            >
              En savoir plus sur le numéro INE et comment le trouver.
            </a>
          }
        >
          <FormInputText
            label={"Numéro INE"}
            name={"Ine"}
            onChange={(e) => setINE(e.target.value)}
            pattern="\w{11}"
            placeholder={"ex: 1234567890G"}
            title={"Le numéro INE doit respecter le format de 11 caractères."}
            value={INE}
          />
        </FormTooltip>
        <div className="helpers-dualInputs">
          <FormInputText
            label={"Nom de naissance"}
            name={"NomDeNaissance"}
            onChange={(e) => setNomDeNaissance(e.target.value)}
            placeholder={"ex: Doe"}
            required={true}
            value={NomDeNaissance}
          />
          <FormInputText
            label={"Nom d'usage"}
            name={"NomDusage"}
            onChange={(e) => setNomDusage(e.target.value)}
            placeholder={"ex: Doe"}
            required={true}
            value={NomDusage}
          />
        </div>
        <FormInputText
          label={"Prénom"}
          name={"Prenom"}
          onChange={(e) => setPrenoms(e.target.value)}
          placeholder={"ex: John"}
          required={true}
          value={Prenoms}
        />
        <FormFieldset label="Adresse">
          <FormInputText
            label={"Numéro et nom de la rue"}
            name={"Adresse[Adresse]"}
            onChange={(e) => setAdresse(e.target.value)}
            placeholder={"ex: 12 rue des tulipes"}
            required={true}
            value={Adresse}
          />
          <div className="helpers-dualInputs">
            <FormInputText
              label={"Appartement"}
              name={"Adresse[Appartement]"}
              onChange={(e) => setAppartement(e.target.value)}
              placeholder={"ex: 2B"}
              value={Appartement}
            />
            <FormInputText
              label={"Bâtiment"}
              name={"Adresse[Batiment]"}
              onChange={(e) => setBatiment(e.target.value)}
              placeholder={"ex: Gauche"}
              value={Batiment}
            />
          </div>
          <FormInputText
            label={"Résidence"}
            name={"Adresse[Residence]"}
            onChange={(e) => setResidence(e.target.value)}
            placeholder={"ex: 2B"}
            value={Residence}
          />
          <div className="helpers-dualInputs">
            <FormInputText
              label={"Code postal"}
              name={"Adresse[CodePostal]"}
              onChange={(e) => setCodePostal(e.target.value)}
              placeholder={"ex: 75010"}
              required={true}
              value={CodePostal}
            />
            <FormInputText
              label={"Ville"}
              name={"Adresse[Ville]"}
              onChange={(e) => setVille(e.target.value)}
              placeholder={"ex: Paris"}
              required={true}
              value={Ville}
            />
          </div>
          <div className="helpers-dualInputs">
            <FormSelectCountry
              label={"Pays"}
              name={"Adresse[Pays]"}
              onChange={(e) => setPays(e.target.value)}
              placeholder={"Choisissez un pays"}
              required={true}
              value={Pays}
            />
          </div>
        </FormFieldset>
        <FormInputTel
          label={"Portable"}
          name={"Portable"}
          onChange={(value) => setPortable(value)}
          required={true}
          value={Portable}
        />
        <FormInputEmail
          label={"E-mail"}
          name={"Email"}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={"ex: john.doe@gmail.com"}
          required={true}
          value={Email}
        />
        <div className={"helpers-dualInputs"}>
          <FormInputDate
            label={"Date de naissance"}
            name={"DateDeNaissance"}
            onChange={(value) => setDateDeNaissance(value)}
            placeholder={"ex: 14/08/2004"}
            required={true}
            value={DateDeNaissance}
          />
          <FormInputText
            label={"Ville de naissance"}
            name={"VilleDeNaissance"}
            onChange={(e) => setVilleDeNaissance(e.target.value)}
            placeholder={"ex: Versailles"}
            required={true}
            value={VilleDeNaissance}
          />
        </div>
        <div className="helpers-dualInputs">
          <FormInputText
            description={"Si le département de naissance est étranger à la France, veuillez saisir \"99\"."}
            label={"Département de naissance"}
            name={"DepartementDeNaissance"}
            onChange={(e) => setDepartementDeNaissance(e.target.value)}
            placeholder={"ex: Yvelines"}
            required={true}
            value={DepartementDeNaissance}
          />
          <FormSelectCountry
            label={"Pays de naissance"}
            name={"PaysDeNaissance"}
            onChange={(e) => setPaysDeNaissance(e.target.value)}
            placeholder={"Choisissez un pays"}
            required={true}
            value={PaysDeNaissance}
          />
        </div>
        <FormSelectCountry
          label={"Nationalité"}
          name={"Nationalite"}
          onChange={(e) => setNationnalite(e.target.value)}
          placeholder={"Choisissez un pays"}
          required={true}
          value={Nationnalite}
        />
        <FormInputRadioGroup
          label={"Situation actuelle"}
          required={true}
        >
          <FormInputRadio
            checked={SituationActuelle === SituationActuelleEnum.Etudiant}
            id={"Etudiant"}
            label={"Étudiant"}
            name={"SituationActuelle"}
            onChange={e => setSituationActuelle(e.target.value)}
            required={true}
            value={SituationActuelleEnum.Etudiant}
          />
          <FormInputRadio
            checked={SituationActuelle === SituationActuelleEnum.Salarie}
            id={"Salarie"}
            label={"Salarié"}
            name={"SituationActuelle"}
            onChange={e => setSituationActuelle(e.target.value)}
            required={true}
            value={SituationActuelleEnum.Salarie}
          />
          <FormInputRadio
            checked={SituationActuelle === SituationActuelleEnum.DemandeurDemploi}
            id={"DemandeurDemploi"}
            label={"Demandeur d'emploi"}
            name={"SituationActuelle"}
            onChange={e => setSituationActuelle(e.target.value)}
            required={true}
            value={SituationActuelleEnum.DemandeurDemploi}
          />
          <FormInputRadio
            checked={SituationActuelle === SituationActuelleEnum.Autre}
            id={"Autre"}
            label={"Autre"}
            name={"SituationActuelle"}
            onChange={e => setSituationActuelle(e.target.value)}
            required={true}
            value={SituationActuelleEnum.Autre}
          />
        </FormInputRadioGroup>
        {SituationActuelle === SituationActuelleEnum.DemandeurDemploi && (
          <FormInputText
            label={"Veuillez indiquer votre identifiant Pôle Emploi"}
            name={"IdentifiantPoleEmploi"}
            onChange={e => setIdentifiantPoleEmploi(e.target.value)}
            placeholder={"ex: 12261986465"}
            value={IdentifiantPoleEmploi}
          />
        )}
        {SituationActuelle === SituationActuelleEnum.Autre && (
          <FormInputText
            label={"Veuillez indiquer votre situation actuelle"}
            name={"AutreSituationActuelle"}
            onChange={e => setAutreSituationActuelle(e.target.value)}
            placeholder={"ex: Entrepreneur"}
            required={true}
            value={AutreSituationActuelle}
          />
        )}
      </div>
      <div className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          type={"submit"}
          title={"Continuer"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}