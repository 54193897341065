import "./CandidaturesSingleExperiencesProfessionnelles.scss"

import {useState} from "react"

import CandidaturesSingleExperiencesProfessionnellesForm
  from "./Form/CandidaturesSingleExperiencesProfessionnellesForm"
import CandidaturesSingleExperiencesProfessionnellesQuestion
  from "./Question/CandidaturesSingleExperiencesProfessionnellesQuestion"

export default function CandidaturesSingleExperiencesProfessionnelles(
  {
    ExperiencesProfessionnelles,
    onExperiencesProfessionnellesChange,
  }
) {
  const [hasExperiencesProfessionnelles, setHasExperiencesProfessionnelles] = useState(ExperiencesProfessionnelles !== null)

  const onFormExperiencesProfessionnellesChange = (ExperienceProfessionnelle) => {
    if (ExperienceProfessionnelle.length === 0) {
      setHasExperiencesProfessionnelles(false)
      return
    }

    onExperiencesProfessionnellesChange(ExperienceProfessionnelle)
  }

  return (
    <div className={"CandidaturesSingleExperiencesProfessionnelles"}>
      <>
        <CandidaturesSingleExperiencesProfessionnellesQuestion
          hasExperiencesProfessionnelles={hasExperiencesProfessionnelles}
          onHasExperiencesProfessionnelles={setHasExperiencesProfessionnelles}
        />
        {!hasExperiencesProfessionnelles && (
          <div className={"helpers-formFooter"}>
            <button
              aria-label={"Continuer"}
              className={"helpers-buttonSubmit tag-button"}
              onClick={() => onExperiencesProfessionnellesChange(null)}
              title={"Continuer"}
            >
              Continuer
            </button>
          </div>
        )}
      </>
      {hasExperiencesProfessionnelles && (
        <CandidaturesSingleExperiencesProfessionnellesForm
          ExperiencesProfessionnelles={(hasExperiencesProfessionnelles && ExperiencesProfessionnelles) ? ExperiencesProfessionnelles : []}
          onExperiencesProfessionnellesChange={onFormExperiencesProfessionnellesChange}
        />
      )}
    </div>
  )
}