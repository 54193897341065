import {Specialisation} from "./constants";
import {Etape} from "./Single/constants";

export const getAnneeLabel = (value) => {
  switch (parseInt(value)) {
    case 1:
      return "1ère année"
    case 2:
      return "2ème année"
    case 3:
      return "3ème année"
    case 4:
      return "4ème année"
    case 5:
      return "5ème année"
  }
}

export const getEtapeLabel = (id) => {
  switch (id) {
    case Etape.EtatCivil:
      return "État civil"
    case Etape.Tuteurs:
      return "Situation de famille"
    case Etape.ParcoursScolaires:
      return "Parcours scolaire"
    case Etape.ExperiencesProfessionnelles:
      return "Expériences professionnelles"
    case Etape.Metiers:
      return "Métiers / débouchés"
    case Etape.Competences:
      return "Compétences"
    case Etape.ProjetProfessionnel:
      return "Projet professionnel"
    case Etape.Alternance:
      return "Alternance"
    case Etape.Ecole:
      return "Votre choix d'école"
    case Etape.SavoirEtreProfessionnel:
      return "Savoir-être professionnel"
    case Etape.PiecesJointes:
      return "Pièces jointes"
  }

  return id
}

export const getSpecialisationLabel = (id) => {
  switch (id) {
    case Specialisation.BachelorDataEtIA:
      return "Bachelor Data & IA"
    case Specialisation.BachelorCybersecurite:
      return "Bachelor Cybersécurité"
    case Specialisation.BachelorDeveloppementWeb:
      return "Bachelor Développement Web"
    case Specialisation.BachelorUIProductDesigner:
      return "Bachelor UI Product designer"
    case Specialisation.BachelorWebmarketingEtUX:
      return "Bachelor Webmarketing & UX"
    case Specialisation.ExecutiveMBADataEtIA:
      return "Executive MBA Data & IA"
    case Specialisation.ExecutiveMBADigitalMarketing:
      return "Executive MBA Digital Marketing"
    case Specialisation.ExecutiveMBAEcommerce:
      return "Executive MBA Ecommerce"
    case Specialisation.ExecutiveMBATechManagement:
      return "Executive MBA Tech Management"
    case Specialisation.MastereCTOEtTechLead:
      return "Mastère CTO & Tech Lead"
    case Specialisation.MastereCybersecurite:
      return "Mastère Cybersécurité"
    case Specialisation.MastereDataEtIA:
      return "Mastère Data & IA"
    case Specialisation.MastereDataPrivacyOfficer:
      return "Mastère Data Privacy Officer"
    case Specialisation.MastereMarketingDigitalEtUX:
      return "Mastère Marketing Digital & UX"
    case Specialisation.MastereProductDesignerExpert:
      return "Mastère Product Designer Expert"
    case Specialisation.MastereProductManager:
      return "Mastère Product Manager"
    case Specialisation.PrepaMastereCybersecurite:
      return "Prépa Mastère Cybersécurité"
    case Specialisation.PrepaMastereDigital:
      return "Prépa mastère digital"
    case Specialisation.PrepaMastereMarketingDesign:
      return "Prèpa mastère Marketing & Design"
    case Specialisation.PrepaMastereTechData:
      return "Prépa Mastère Tech & Data"
    case Specialisation.ProgrammeGrandeEcole:
      return "Programme grande école"
  }

  return id
}


export const scrollToFirstError = (form) => {
  const firstInvalidElement = Array.from(form.elements).find((element) => {
    return !element.validity.valid
  })

  if (firstInvalidElement) {
    firstInvalidElement.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }
}