import "./CandidaturesSinglePiecesJointes.scss"

import FormInputFile from "../../../Form/InputFile/FormInputFile"
import FormInputHead from "../../../Form/InputHead/FormInputHead"
import {getSpecialisationOptions} from "../../Initialisation/Form/helpers";

export default function CandidaturesSinglePiecesJointes(
  {
    enableSoumettreUneCandidatureButton,
    onPieceJointeCategorieSubmit,
    onValidate,
    PiecesJointes,
    PiecesJointesEtapes,
  }
) {
  const uploadFile = (categorie, files, limitUpload) => {
    if (files.length === 0) {
      return
    }

    if (limitUpload && (files.length > limitUpload)) {
      alert(`Le nombre maximal de fichiers autorisés est limité à ${limitUpload}.`)
      return
    }

    const formDataFiles = new FormData()
    formDataFiles.append("Categorie", categorie)
    Object.values(files).forEach(file => {
      formDataFiles.append("Documents", file)
    })

    onPieceJointeCategorieSubmit(formDataFiles)
  }

  return (
    <section className={"CandidaturesSinglePiecesJointes"}>
      <div className="helpers-formBody">
        <p className={"tag-p"}>
          Pour constituer votre dossier, nous nécessitons les éléments suivants.
        </p>
        <div className="CandidaturesSinglePiecesJointes-input">
          <FormInputHead
            label={"Photo"}
            required={typeof PiecesJointesEtapes.Photo !== "undefined"}
          />
          <FormInputFile
            accept={"image/png, image/jpeg, image/jpg, application/pdf"}
            name={"PiecesJointesPhoto"}
            onChange={(e) => uploadFile("Photo", e.target.files)}
            placeholder={"ex: Uploader la photo"}
            required={typeof PiecesJointesEtapes.Photo !== "undefined"}
            uploaded={PiecesJointes.Photo !== ""}
          />
        </div>
        <div className="CandidaturesSinglePiecesJointes-input">
          <FormInputHead
            label={"Pièce d'identité (recto/verso)"}
            required={typeof PiecesJointesEtapes.PieceDidentite !== "undefined"}
          />
          <FormInputFile
            accept={"image/png, image/jpeg, image/jpg, application/pdf"}
            multiple={true}
            name={"PiecesJointesPieceDidentite"}
            onChange={(e) => uploadFile("PieceDidentite", e.target.files, 2)}
            placeholder={"Uploader la pièce d'identité"}
            required={typeof PiecesJointesEtapes.PieceDidentite !== "undefined"}
            textAfterUpload={`Documents uploadés (${PiecesJointes?.PieceDidentite?.length})`}
            title={"Ajouter les documents"}
            uploaded={PiecesJointes.PieceDidentite !== null && PiecesJointes.PieceDidentite.length > 0}
          />
        </div>
        <div className="CandidaturesSinglePiecesJointes-input">
          <FormInputHead
            description={"Reçu par l'étudiant (pour l'année en cours, le relevé\n" +
              "du 1er semestre, ou à défaut le certificat de scolarité, suffit si le relevé annuel n'est pas encore\n" +
              "disponible)."}
            label={"Dernier relevé de notes"}
            required={typeof PiecesJointesEtapes.DernierReleveDeNotes !== "undefined"}
          />
          <FormInputFile
            accept={"image/png, image/jpeg, image/jpg, application/pdf"}
            name={"PiecesJointesDernierReleveDeNotes"}
            onChange={(e) => uploadFile("DernierReleveDeNotes", e.target.files)}
            placeholder={"Uploader la pièce d'identité"}
            required={typeof PiecesJointesEtapes.DernierReleveDeNotes !== "undefined"}
            uploaded={PiecesJointes.DernierReleveDeNotes !== ""}
          />
        </div>
        <div className="CandidaturesSinglePiecesJointes-input">
          <FormInputHead
            description={"Avec certification de conformité et traduction en français pour les documents non francophones."}
            label={"Diplômes"}
            required={typeof PiecesJointesEtapes.Diplomes !== "undefined"}
          />
          <FormInputFile
            accept={"image/png, image/jpeg, image/jpg, application/pdf"}
            multiple={true}
            name={"PiecesJointesDiplomes"}
            onChange={(e) => uploadFile("Diplomes", e.target.files, 10)}
            placeholder={"Uploader la pièce d'identité"}
            required={typeof PiecesJointesEtapes.Diplomes !== "undefined"}
            textAfterUpload={`Documents uploadés (${PiecesJointes?.Diplomes?.length})`}
            title={"Ajouter les documents"}
            uploaded={PiecesJointes.Diplomes !== null && PiecesJointes.Diplomes.length > 0}
          />
        </div>
        <div className="CandidaturesSinglePiecesJointes-input">
          <FormInputHead
            label={"Curriculum Vitæ (CV)"}
            required={typeof PiecesJointesEtapes.CV !== "undefined"}
          />
          <FormInputFile
            accept={"image/png, image/jpeg, image/jpg, application/pdf"}
            name={"PiecesJointesCV"}
            onChange={(e) => uploadFile("CV", e.target.files)}
            placeholder={"Uploader la pièce d'identité"}
            required={typeof PiecesJointesEtapes.CV !== "undefined"}
            uploaded={PiecesJointes.CV !== ""}
          />
        </div>
        <div className="CandidaturesSinglePiecesJointes-input">
          <div className={"FormInputHead"}>
            <p className={"tag-label"}>Pièces complémentaires</p>
            <div className={"tag-p is-s"}>
              <ul className={"CandidaturesSinglePiecesJointes-list"}>
                <li className={"CandidaturesSinglePiecesJointes-listItem"}>
                  Tout résultat officiel obtenu à un test de langue tel que le TOEFL, TOEIC, IELTS, TCF, GMAT, etc.
                </li>
                <li className={"CandidaturesSinglePiecesJointes-listItem"}>
                  Une lettre de soutien d'un professeur, d'un chef d'établissement ou d'un employé d'une société dans
                  laquelle vous avez travaillé.
                </li>
                <li className={"CandidaturesSinglePiecesJointes-listItem"}>
                  Les attestations de stage et les certificats de travail.
                </li>
              </ul>
            </div>
          </div>
          <FormInputFile
            accept={"image/png, image/jpeg, image/jpg, application/pdf"}
            multiple={true}
            name={"PiecesJointesAutres"}
            onChange={(e) => uploadFile("Autres", e.target.files, 10)}
            placeholder={"Uploader la pièce d'identité"}
            textAfterUpload={`Documents uploadés (${PiecesJointes?.Autres?.length})`}
            title={"Ajouter les documents"}
            required={typeof PiecesJointesEtapes.Autres !== "undefined"}
            uploaded={PiecesJointes.Autres !== null && PiecesJointes.Autres.length > 0}
          />
          <p className={"tag-p is-s"}>Maximum 10 documents</p>
        </div>
      </div>
      <section className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          disabled={!enableSoumettreUneCandidatureButton}
          onClick={onValidate}
          title={"Soumettre ma candidature"}
        >
          Continuer
        </button>
      </section>
    </section>
  )
}