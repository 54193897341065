import "./CandidaturesSinglePostSoumission.scss"

import Logo from "../../../Logo/Logo"
import {getAnneeLabel, getSpecialisationLabel} from "../../helpers"
import {Statut} from "../constants"
import {candidatureSessionAsMMMMYYYY} from "../helpers"

export default function CandidaturesSinglePostSoumission({candidature}) {
  const annee = getAnneeLabel(candidature.Annee)
  const session = candidatureSessionAsMMMMYYYY(candidature)
  const specialisation = getSpecialisationLabel(candidature.Specialisation)

  return (
    <div className={"CandidaturesSinglePostSoumission"}>
      <div className={"CandidaturesSinglePostSoumission-head"}>
        <div className={"CandidaturesSinglePostSoumission-headLeft"}>
          <Logo size={"l"}/>
        </div>
        <div className={"CandidaturesSinglePostSoumission-headRight"}>
          <h1 className={"tag-h2"}>Candidature en ligne</h1>
          <div>
            <ul className={"tag-p is-l"}>
              <li>{specialisation}</li>
              <li>{annee}</li>
              <li>{session}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={"CandidaturesSinglePostSoumission-content"}>
        {candidature.Statut === Statut.AValider && (
          <>
            <div className={"helpers-badge is-AValider"}>Votre candidature est en cours de validation</div>
            <p className={"tag-p is-m"}>
              Votre candidature est en cours d'examen par l'équipe d'admission. Nous reviendrons vers vous dans les plus
              brefs délais pour d'éventuelles corrections ou la validation de votre candidature.
            </p>
            <div className={"CandidaturesSinglePostSoumission-contactContainer"}>
              <p className={"tag-p is-m"}>Si vous avez des questions, n'hésitez pas à nous contacter.</p>
              <div className={"CandidaturesSinglePostSoumission-contact"}>
                <a className={"tag-p is-m tag-a"} href="tel:0176361007">01.76.36.10.07</a>
                <span className={"CandidaturesSinglePostSoumission-bigDot"}>•</span>
                <a className={"tag-p is-m tag-a"} href="mailto:admissions@hetic.fr">admissions@hetic.fr</a>
              </div>
            </div>
            <p></p>
          </>
        )}
        {candidature.Statut === Statut.Valider && (
          <>
            <div className={"helpers-badge is-Valider"}>Votre candidature est validée</div>
            <p className={"tag-p is-m"}>
              Votre candidature au {specialisation} à HETIC a été retenue.
              Notre équipe vous contactera prochainement pour une session d'admission au cours de laquelle vous
              recevrez toutes les informations nécessaires pour finaliser votre inscription.
            </p>
          </>
        )}
        {candidature.Statut === Statut.Stopper && (
          <div className={"helpers-badge is-Stopper"}>Stopper</div>
        )}
      </div>
    </div>
  )
}