import "./CandidaturesSingleLayoutMobileNavigation.scss"

import React, {useEffect} from "react"
import classNames from "classnames"

import Logo from "../../../../Logo/Logo"
import IconChevronRight from "../../../../Icon/IconChevronRight"
import {candidatureEtapeIsMade, candidatureProgressionPercent, candidatureSortedEtapes} from "../../helpers"
import {getEtapeLabel} from "../../../helpers";

export default function CandidaturesSingleLayoutMobileNavigation(
  {
    candidature,
    currentEtape,
    onEtapeChange,
  }
) {
  const sortedEtapes = candidatureSortedEtapes(candidature)

  useEffect(() => {
    document.querySelector(`#${currentEtape}`)
      .scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "start",
      })
  }, [currentEtape])

  return (
    <div className={"CandidaturesSingleLayoutMobileNavigation"}>
      <div className={"CandidaturesSingleLayoutMobileNavigation-head"}>
        <div className={"CandidaturesSingleLayoutMobileNavigation-headLeft"}>
          <div className={"CandidaturesSingleLayoutMobileNavigation-logo"}>
            <Logo size={"s"}/>
          </div>
          <div className={"CandidaturesSingleLayoutMobileNavigation-pourcentage"}>
            <p className={"tag-p is-m"}>{candidatureProgressionPercent(candidature)}%</p>
          </div>
        </div>
        <div className={"CandidaturesSingleLayoutMobileNavigation-headRight"}>
          <div className={"CandidaturesSingleLayoutMobileNavigation-titleContainer"}>
            <h1 className={"CandidaturesSingleLayoutMobileNavigation-title tag-p is-l"}>
              Candidature en ligne
            </h1>
          </div>
          <div className={"CandidaturesSingleLayoutMobileNavigation-progressBar"}>
            <div
              className={"CandidaturesSingleLayoutMobileNavigation-progressBarInner"}
              style={{"--progress": `${candidatureProgressionPercent(candidature)}%`}}
            >
            </div>
          </div>
        </div>
      </div>
      <ul className={"CandidaturesSingleLayoutMobileNavigation-etapesScrollContainer"}>
        {sortedEtapes.map((etape, key) => {
          const etapeIsUnlocked = key === 0 || candidatureEtapeIsMade(candidature, sortedEtapes[key - 1])
          return (
            <li className={"CandidaturesSingleLayoutMobileNavigation-etapesListItem"} key={etape}>
              <button
                aria-label={getEtapeLabel(etape)}
                className={classNames("CandidaturesSingleLayoutMobileNavigation-button tag-p is-s is-extra", {
                  "is-current": currentEtape === etape,
                  "is-passed": candidatureEtapeIsMade(candidature, etape),
                  "is-unlocked": etapeIsUnlocked,
                })}
                disabled={!(etapeIsUnlocked || candidatureEtapeIsMade(candidature, etape))}
                id={etape}
                onClick={() => onEtapeChange(etape)}
                title={getEtapeLabel(etape)}
              >
                {getEtapeLabel(etape)}
              </button>
              <div className={"CandidaturesSingleLayoutMobileNavigation-etapesListItemArrow"}>
                <IconChevronRight/>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}