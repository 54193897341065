import "./CandidaturesSingleTuteurs.scss"

import {useState} from "react"

import CandidaturesSingleTuteursQuestion from "./Question/CandidaturesSingleTuteursQuestion"
import CandidaturesSingleTuteursForm from "./Form/CandidaturesSingleTuteursForm"
import {getAgeFromBirthDate} from "./helpers"

export default function CandidaturesSingleTuteurs({EtatCivil, Tuteurs, onTuteursChange}) {
  const [hasTuteurs, setHasTuteurs] = useState(Tuteurs !== null)
  const [isMineur] = useState(getAgeFromBirthDate(EtatCivil.DateDeNaissance) < 18)

  const onFormTuteursChange = (Tuteurs) => {
    if (Tuteurs.length === 0) {
      setHasTuteurs(false)
      return
    }

    onTuteursChange(Tuteurs)
  }

  return (
    <section className={"CandidaturesSingleTuteurs"}>
      <CandidaturesSingleTuteursQuestion
        hasTuteurs={hasTuteurs}
        isMineur={isMineur}
        onHasTuteursChange={setHasTuteurs}
      />
      {(!hasTuteurs && !isMineur) && (
        <div className={"helpers-formFooter"}>
          <button
            aria-label={"Continuer"}
            className={"helpers-buttonSubmit tag-button"}
            onClick={() => onTuteursChange(null)}
            title={"Continuer"}
          >
            Continuer
          </button>
        </div>
      )}
      {(hasTuteurs || isMineur) && (
        <CandidaturesSingleTuteursForm
          Tuteurs={(hasTuteurs && Tuteurs) ? Tuteurs : []}
          isMineur={isMineur}
          onTuteursChange={onFormTuteursChange}
        />
      )}
    </section>
  )
}