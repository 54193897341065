import "./Error.scss"

import IconHome from "../Icon/IconHome"

export default function Error(
  {
    code,
    message,
  }
) {
  return (
    <main className={"Error"}>
      <div className={"Error-content"}>
        <p className={"Error-code tag-h1"}>Erreur {code}</p>
        <p className={"tag-p is-l"}>{message}</p>
      </div>
      <a className={"Error-button tag-button"} href={"/"}>
        <IconHome size={"20"}/>
        Retour à l'accueil
      </a>
    </main>
  )
}