import "./CandidaturesSingleCompetences.scss"

import {useState} from "react"

import FormTextarea from "../../../Form/FormTextarea"
import {scrollToFirstError} from "../../helpers"

export default function CandidaturesSingleCompetences({Competences, onCompetencesChange}) {
  const [
    LesCompetencesEnLienAvecLeOuLesMetiersQueJePossedeDeja,
    setLesCompetencesEnLienAvecLeOuLesMetiersQueJePossedeDeja,
  ] = useState(Competences.LesCompetencesEnLienAvecLeOuLesMetiersQueJePossedeDeja)
  const [
    LesPrincipalesActivitesDuOuDesMetiersQueJeConnais,
    setLesPrincipalesActivitesDuOuDesMetiersQueJeConnais,
  ] = useState(Competences.LesPrincipalesActivitesDuOuDesMetiersQueJeConnais)

  const submit = (event) => {
    event.preventDefault()

    if(!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onCompetencesChange({
      LesCompetencesEnLienAvecLeOuLesMetiersQueJePossedeDeja,
      LesPrincipalesActivitesDuOuDesMetiersQueJeConnais,
    })
  }

  return (
    <form className={"CandidaturesSingleCompetences"} onSubmit={submit} noValidate>
      <div className={"helpers-formBody"}>
        <FormTextarea
          label={
            <>
              Connaissez-vous les principales activités du ou des métiers visés par le diplôme ?
              <span className={"helpers-ifYes"}>
                Si oui, citez des exemples ci-dessous
              </span>
            </>
          }
          name={"LesCompetencesEnLienAvecLeOuLesMetiersQueJePossedeDeja"}
          onChange={(e) => setLesCompetencesEnLienAvecLeOuLesMetiersQueJePossedeDeja(e.target.value)}
          value={LesCompetencesEnLienAvecLeOuLesMetiersQueJePossedeDeja}
        />
        <FormTextarea
          label={
            <>
              Possédez-vous déjà les compétences en lien avec le ou les métiers visés ?
              <span className={"helpers-ifYes"}>
                Si oui, citez les compétences déjà acquises ci dessous
              </span>
            </>
          }
          name={"LesPrincipalesActivitesDuOuDesMetiersQueJeConnais"}
          onChange={(e) => setLesPrincipalesActivitesDuOuDesMetiersQueJeConnais(e.target.value)}
          value={LesPrincipalesActivitesDuOuDesMetiersQueJeConnais}
        />
      </div>
      <div className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          type={"submit"}
          title={"Continuer"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}