import "./CandidaturesInitialisation.scss"

import {useState} from "react"

import CandidaturesInitialisationForm from "./Form/CandidaturesInitialisationForm"
import CandidaturesInitialisationSidebar from "./Sidebar/CandidaturesInitialisationSidebar"

export default function CandidaturesInitialisation() {
  const [showForm, setShowForm] = useState(false)

  return (
    <div className="CandidaturesInitialisation">
      <aside className="CandidaturesInitialisation-sidebar">
        <CandidaturesInitialisationSidebar onButtonStartClick={() => setShowForm(true)}/>
      </aside>
      <main className={`CandidaturesInitialisation-form ${showForm ? "is-open" : ""}`}>
        <CandidaturesInitialisationForm onButtonCloseClick={() => setShowForm(false)}/>
      </main>
    </div>
  )
}