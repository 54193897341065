import "./CandidaturesSingleParcoursScolaires.scss"

import {useEffect, useState} from "react"

import IconTrash from "../../../Icon/IconTrash"
import FormFieldset from "../../../Form/Fieldset/FormFieldset"
import FormInputNumber from "../../../Form/FormInputNumber"
import FormInputText from "../../../Form/FormInputText"
import FormInputRadioGroup from "../../../Form/InputRadio/Group/FormInputRadioGroup"
import FormInputRadio from "../../../Form/InputRadio/FormInputRadio"
import IconPlus from "../../../Icon/IconPlus"
import {scrollToFirstError} from "../../helpers"

export default function CandidaturesSingleParcoursScolaires(
  {
    ParcoursScolaires,
    onParcoursScolairesChange,
  }
) {
  const [localParcoursScolaires, setLocalParcoursScolaires] = useState(ParcoursScolaires ?? [])

  useEffect(() => {
    if (localParcoursScolaires.length === 0) {
      pushEmptyParcoursScolaire()
    }
  }, [])

  const pushEmptyParcoursScolaire = () => {
    setLocalParcoursScolaires(ParcoursScolaires => [...ParcoursScolaires, {
      Annee: undefined,
      DiplomeOuTitrePrepare: "",
      Etablissement: {
        Adresse: "",
        Nom: "",
      },
      Obtenu: undefined,
    }])
  }

  const deleteParcoursScolaire = (parcoursScolaireIndex) => {
    if (localParcoursScolaires.length > 1) {
      setLocalParcoursScolaires([
        ...localParcoursScolaires.slice(0, parcoursScolaireIndex),
        ...localParcoursScolaires.slice(parcoursScolaireIndex + 1),
      ])
    }
  }

  const updateParcoursScolaire = (parcoursScolaireIndex, data) => {
    setLocalParcoursScolaires(parcoursScolaires => {
      parcoursScolaires[parcoursScolaireIndex] = data
      return [...parcoursScolaires]
    })
  }

  const submit = (event) => {
    event.preventDefault()

    if (!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onParcoursScolairesChange(localParcoursScolaires)
  }

  return (
    <section className={"CandidaturesSingleParcoursScolaires"}>
      <form className={"CandidaturesSingleParcoursScolaires-form"} onSubmit={submit} noValidate>
        <div className="helpers-formBody">
          <p className={"tag-p"}>Votre parcours scolaire de la terminale à votre dernière année d'études.</p>
          {localParcoursScolaires && localParcoursScolaires.map((parcoursScolaire, parcoursScolaireIndex) => (
            <div className={"CandidaturesSingleParcoursScolaires-parcoursScolaire"} key={parcoursScolaireIndex}>
              <FormFieldset label={`Parcours scolaire ${parcoursScolaireIndex + 1}`}>
                {parcoursScolaireIndex === 0 && (
                  <p className={"CandidaturesSingleParcoursScolaires-messageFirstParcours tag-p"}>
                    Il est impératif de débuter votre parcours scolaire par le diplôme du baccalauréat,
                    sans quoi votre admission au cursus ne pourra être envisagée.
                  </p>
                )}
                <button
                  aria-label={"Supprimer le parcours scolaire"}
                  className={"CandidaturesSingleParcoursScolaires-parcoursScolaireButtonRemove"}
                  onClick={() => deleteParcoursScolaire(parcoursScolaireIndex)}
                  title={"Supprimer le parcours scolaire"}
                  type={"button"}
                >
                  <IconTrash size={"20"}/>
                </button>
                <FormInputNumber
                  label={"Année"}
                  name={`Annee[]`}
                  onChange={(e) => updateParcoursScolaire(parcoursScolaireIndex, {
                    ...parcoursScolaire,
                    Annee: parseInt(e.target.value),
                  })}
                  placeholder={`ex: ${new Date().getFullYear() - 1}`}
                  required={true}
                  value={parcoursScolaire.Annee}
                />
                <FormInputText
                  label={"Diplôme ou titre préparé"}
                  name={`DiplomeOuTitrePrepare[]`}
                  onChange={(e) => updateParcoursScolaire(parcoursScolaireIndex, {
                    ...parcoursScolaire,
                    DiplomeOuTitrePrepare: e.target.value,
                  })}
                  placeholder={`${parcoursScolaireIndex === 0 ? "ex: Bac STI2D" : "ex: Mastère CTO & Tech Lead"}`}
                  required={true}
                  value={parcoursScolaire.DiplomeOuTitrePrepare}
                />
                <FormInputRadioGroup label={"Obtenu"} required={true}>
                  <FormInputRadio
                    checked={parcoursScolaire.Obtenu === "EnCours"}
                    id={`ObtenuEnCoursDObtention-${parcoursScolaireIndex}`}
                    label={"En cours d'obtention"}
                    name={`Obtenu-${parcoursScolaireIndex}[]`}
                    onChange={() => updateParcoursScolaire(parcoursScolaireIndex, {
                      ...parcoursScolaire,
                      Obtenu: "EnCours",
                    })}
                    required={true}
                    value={`0-${parcoursScolaireIndex}`}
                  />
                  <FormInputRadio
                    checked={parcoursScolaire.Obtenu === "Oui"}
                    id={`ObtenuTrue-${parcoursScolaireIndex}`}
                    label={"Oui"}
                    name={`Obtenu-${parcoursScolaireIndex}[]`}
                    onChange={() => updateParcoursScolaire(parcoursScolaireIndex, {
                      ...parcoursScolaire,
                      Obtenu: "Oui",
                    })}
                    required={true}
                    value={`1-${parcoursScolaireIndex}`}
                  />
                  <FormInputRadio
                    checked={parcoursScolaire.Obtenu === "Non"}
                    id={`ObtenuFalse-${parcoursScolaireIndex}`}
                    label={"Non"}
                    name={`Obtenu-${parcoursScolaireIndex}[]`}
                    onChange={() => updateParcoursScolaire(parcoursScolaireIndex, {
                      ...parcoursScolaire,
                      Obtenu: "Non",
                    })}
                    required={true}
                    value={`0-${parcoursScolaireIndex}`}
                  />
                </FormInputRadioGroup>
                <FormInputText
                  label={"Nom de l'établissement"}
                  name={`Etablissement[][Nom]`}
                  onChange={(e) => updateParcoursScolaire(parcoursScolaireIndex, {
                    ...parcoursScolaire,
                    Etablissement: {
                      ...parcoursScolaire.Etablissement,
                      Nom: e.target.value,
                    },
                  })}
                  placeholder={`${parcoursScolaireIndex === 0 ? "ex: Lycée Condorcet" : "ex: HETIC"}`}
                  required={true}
                  value={parcoursScolaire.Etablissement.Nom}
                />
                <FormInputText
                  label={"Adresse de l'établissement"}
                  name={`Etablissement[][Adresse]`}
                  onChange={(e) => updateParcoursScolaire(parcoursScolaireIndex, {
                    ...parcoursScolaire,
                    Etablissement: {
                      ...parcoursScolaire.Etablissement,
                      Adresse: e.target.value,
                    },
                  })}
                  placeholder={`${parcoursScolaireIndex === 0 ? "ex: 8 Rue du Havre, 75009 Paris, France" : "ex: 27 Bis Rue du Progrès, 93100 Montreuil"}`}
                  required={true}
                  value={parcoursScolaire.Etablissement.Adresse}
                />
              </FormFieldset>
            </div>
          ))}
          <button
            aria-label={"Ajouter un parcours scolaire"}
            className={"tag-button is-outline"}
            onClick={() => pushEmptyParcoursScolaire()}
            title={"Ajouter un parcours scolaire"}
            type={"button"}
          >
            Ajouter un parcours scolaire
            <IconPlus size={"20"}/>
          </button>
        </div>
        <div className={"helpers-formFooter"}>
          <button
            aria-label={"Continuer"}
            className={"helpers-buttonSubmit tag-button"}
            title={"Continuer"}
            type={"submit"}
          >
            Continuer
          </button>
        </div>
      </form>
    </section>
  )
}