import "./CandidaturesSingleTuteursQuestion.scss"

import FormInputRadioGroup from "../../../../Form/InputRadio/Group/FormInputRadioGroup"
import FormInputRadio from "../../../../Form/InputRadio/FormInputRadio"

export default function CandidaturesSingleTuteursQuestion(
  {
    hasTuteurs,
    isMineur,
    onHasTuteursChange,
  }
) {
  return (
    <div className={"CandidaturesSingleTuteursQuestion helpers-formBody"}>
      <div className={"CandidaturesSingleTuteursQuestion-head"}>
        <h2 className={"tag-label"}>Avez-vous un tuteur ?</h2>
        {isMineur && (
          <p className="tag-p is-m">À remplir obligatoirement pour les mineurs.</p>
        )}
      </div>
      {!isMineur && (
        <FormInputRadioGroup>
          <FormInputRadio
            checked={hasTuteurs === true}
            id={"Yes"}
            label={"Oui"}
            name={"hasTuteurs"}
            onChange={() => onHasTuteursChange(true)}
            required={true}
            value={"1"}
          />
          <FormInputRadio
            checked={hasTuteurs === false}
            id={"No"}
            label={"Non"}
            name={"hasTuteurs"}
            onChange={() => onHasTuteursChange(false)}
            required={true}
            value={"0"}
          />
        </FormInputRadioGroup>
      )}
    </div>
  )
}