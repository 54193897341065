import "./CandidaturesSingleExperiencesProfessionnellesForm.scss"

import {useEffect, useState} from "react"

import FormFieldset from "../../../../Form/Fieldset/FormFieldset"
import IconTrash from "../../../../Icon/IconTrash"
import FormInputText from "../../../../Form/FormInputText"
import FormInputRadioGroup from "../../../../Form/InputRadio/Group/FormInputRadioGroup"
import FormInputRadio from "../../../../Form/InputRadio/FormInputRadio"
import FormTextarea from "../../../../Form/FormTextarea"
import {Contrat as Contrats} from "../../constants"
import {scrollToFirstError} from "../../../helpers"
import IconPlus from "../../../../Icon/IconPlus";

export default function CandidaturesSingleExperiencesProfessionnellesForm(
  {
    ExperiencesProfessionnelles,
    onExperiencesProfessionnellesChange,
  }
) {
  const [localExperiencesProfessionnelles, setLocalExperiencesProfessionnelles] = useState(ExperiencesProfessionnelles)

  useEffect(() => {
    if (localExperiencesProfessionnelles.length === 0) {
      pushEmptyExperienceProfessionnelle()
    }
  }, [])

  const pushEmptyExperienceProfessionnelle = () => {
    setLocalExperiencesProfessionnelles(ExperiencesProfessionnelles => [...ExperiencesProfessionnelles, {
      Entreprise: "",
      Fonction: "",
      Contrat: "",
      ContratVIEPays: "",
      Duree: "",
      Description: "",
    }])
  }

  const deleteExperienceProfessionnelle = (experienceProfessionnelleIndex) => {
    if (localExperiencesProfessionnelles.length === 1) {
      onExperiencesProfessionnellesChange([])
    }

    setLocalExperiencesProfessionnelles([
      ...localExperiencesProfessionnelles.slice(0, experienceProfessionnelleIndex),
      ...localExperiencesProfessionnelles.slice(experienceProfessionnelleIndex + 1),
    ])
  }

  const updateExperienceProfessionnelle = (experienceProfessionnelleIndex, data) => {
    setLocalExperiencesProfessionnelles(experiencesProfessionnelles => {
      experiencesProfessionnelles[experienceProfessionnelleIndex] = data
      return [...experiencesProfessionnelles]
    })
  }

  const submit = (event) => {
    event.preventDefault()

    if (!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onExperiencesProfessionnellesChange(localExperiencesProfessionnelles)
  }

  return (
    <form className={"CandidaturesSingleExperiencesProfessionnellesForm"} onSubmit={submit} noValidate>
      <div className={"helpers-formBody"}>
        {localExperiencesProfessionnelles.length > 0
          && localExperiencesProfessionnelles.map((experienceProfessionnelle, experienceProfessionnelleIndex) => (
            <div
              className={"CandidaturesSingleExperiencesProfessionnellesForm-experienceProfessionnelle"}
              key={experienceProfessionnelleIndex}
            >
              <FormFieldset label={`Expérience professionnelle ${experienceProfessionnelleIndex + 1}`}>
                <button
                  aria-label={"Supprimer l'expérience professionnelle"}
                  className={"CandidaturesSingleExperiencesProfessionnellesForm-buttonRemove"}
                  onClick={() => deleteExperienceProfessionnelle(experienceProfessionnelleIndex)}
                  title={"Supprimer l'expérience professionnelle"}
                  type={"button"}
                >
                  <IconTrash size={"20"}/>
                </button>
                <FormInputText
                  label={"Entreprise"}
                  name={`Entreprise[]`}
                  onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                    ...experienceProfessionnelle,
                    Entreprise: e.target.value,
                  })}
                  placeholder={"ex: Havas"}
                  required={true}
                  value={experienceProfessionnelle.Entreprise}
                />
                <FormInputText
                  label={"Fonction occupée"}
                  name={`Fonction[]`}
                  onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                    ...experienceProfessionnelle,
                    Fonction: e.target.value,
                  })}
                  placeholder={"ex: Développeur frontend"}
                  required={true}
                  value={experienceProfessionnelle.Fonction}
                />
                <FormInputRadioGroup label={"Type de contrat"} required={true}>
                  <FormInputRadio
                    checked={experienceProfessionnelle.Contrat === Contrats.Stage}
                    id={`ContratStage-${experienceProfessionnelleIndex}`}
                    label={Contrats.Stage}
                    name={`Contrat-${experienceProfessionnelleIndex}[]`}
                    onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                      ...experienceProfessionnelle,
                      Contrat: e.target.value,
                      ContratVIEPays: "",
                    })}
                    required={true}
                    value={Contrats.Stage}
                  />
                  <FormInputRadio
                    checked={experienceProfessionnelle.Contrat === Contrats.Alternance}
                    id={`${Contrats.Alternance}-${experienceProfessionnelleIndex}`}
                    label={Contrats.Alternance}
                    name={`Contrat-${experienceProfessionnelleIndex}[]`}
                    onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                      ...experienceProfessionnelle,
                      Contrat: e.target.value,
                      ContratVIEPays: "",
                    })}
                    required={true}
                    value={Contrats.Alternance}
                  />
                  <FormInputRadio
                    checked={experienceProfessionnelle.Contrat === Contrats.CDD}
                    id={`${Contrats.CDD}-${experienceProfessionnelleIndex}`}
                    label={Contrats.CDD}
                    name={`Contrat-${experienceProfessionnelleIndex}[]`}
                    onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                      ...experienceProfessionnelle,
                      Contrat: e.target.value,
                      ContratVIEPays: "",
                    })}
                    required={true}
                    value={Contrats.CDD}
                  />
                  <FormInputRadio
                    checked={experienceProfessionnelle.Contrat === Contrats.CDI}
                    id={`${Contrats.CDI}-${experienceProfessionnelleIndex}`}
                    label={Contrats.CDI}
                    name={`Contrat-${experienceProfessionnelleIndex}[]`}
                    onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                      ...experienceProfessionnelle,
                      Contrat: e.target.value,
                      ContratVIEPays: "",
                    })}
                    required={true}
                    value={Contrats.CDI}
                  />
                  <FormInputRadio
                    checked={experienceProfessionnelle.Contrat === Contrats.VIE}
                    id={`${Contrats.VIE}-${experienceProfessionnelleIndex}`}
                    label={Contrats.VIE}
                    name={`Contrat-${experienceProfessionnelleIndex}[]`}
                    onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                      ...experienceProfessionnelle,
                      Contrat: e.target.value,
                    })}
                    required={true}
                    value={Contrats.VIE}
                  />
                </FormInputRadioGroup>
                {experienceProfessionnelle.Contrat === Contrats.VIE && (
                  <FormInputText
                    label={"Pays dans lequel vous avez effectué votre VIE"}
                    name={`Pays-${experienceProfessionnelleIndex}[]`}
                    onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                      ...experienceProfessionnelle,
                      ContratVIEPays: e.target.value,
                    })}
                    placeholder={"ex: Espagne"}
                    value={experienceProfessionnelle.ContratVIEPays}
                  />
                )}
                <FormInputText
                  label={"Durée du contrat"}
                  name={`Duree-${experienceProfessionnelleIndex}[]`}
                  onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                    ...experienceProfessionnelle,
                    Duree: e.target.value,
                  })}
                  placeholder={"ex: 9 mois"}
                  required={true}
                  value={experienceProfessionnelle.Duree}
                />
                <FormTextarea
                  label={"Description du poste"}
                  name={`Description-${experienceProfessionnelleIndex}[]`}
                  onChange={(e) => updateExperienceProfessionnelle(experienceProfessionnelleIndex, {
                    ...experienceProfessionnelle,
                    Description: e.target.value,
                  })}
                  required={true}
                  value={experienceProfessionnelle.Description}
                />
              </FormFieldset>
            </div>
          ))}
        <button
          aria-label={"Ajouter une expérience professionnelle"}
          className={"tag-button is-outline"}
          onClick={() => pushEmptyExperienceProfessionnelle()}
          title={"Ajouter une expérience professionnelle"}
          type={"button"}
        >
          Ajouter une expérience professionnelle
          <IconPlus size={"20"}/>
        </button>
      </div>
      <div className={"helpers-formFooter"}>
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          title={"Continuer"}
          type={"submit"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}