import ReactDOM from "react-dom/client"
import Router from "./components/Router/Router"

import "./style/global/index.scss"

ReactDOM.createRoot(
  document.getElementById("root")
).render(
  <Router/>
)
