import React from "react"
import {createBrowserRouter, RouterProvider} from "react-router-dom"

import ErrorElement from "./ErrorElement"
import InscriptionsHome from "../Inscriptions/Home/InscriptionsHome"
import CandidaturesInitialisation from "../Candidatures/Initialisation/CandidaturesInitialisation"
import CandidaturesSingle from "../Candidatures/Single/CandidaturesSingle"
import {api} from "../Candidatures/services"

export default function Router() {

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <ErrorElement/>,
      children: window.location.hostname.includes("inscription") ? [
        {
          path: "/",
          element: <InscriptionsHome/>,
        },
      ] : [
        {
          path: "/",
          element: <CandidaturesInitialisation/>,
        },
        {
          path: "/candidatures/:id",
          element: <CandidaturesSingle/>,
          loader: ({params}) => api("GET", `/candidatures/${params.id}`)
        }
      ]
    }
  ])

  return (
    <RouterProvider router={router}/>
  )
}