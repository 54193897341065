import {AxiosError} from "axios"
import {useEffect, useState} from "react"
import {useRouteError, isRouteErrorResponse} from "react-router-dom"

import Error from "../Error/Error"

export default function ErrorElement() {
  const error = useRouteError()
  const [status, setStatus] = useState(null)

  useEffect(() => {
    if (error instanceof AxiosError) {
      setStatus(error.response.status)
    } else if (isRouteErrorResponse(error)) {
      setStatus(error.status)
    }
  }, [error])

  if (status === 404) {
    return (
      <Error code={"404"} message={"Page not found."}/>
    )
  }

  return (
    <Error code={"500"} message={"Internal server error."}/>
  )
}