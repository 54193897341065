import "./InscriptionsHome.scss"

import Logo from "../../Logo/Logo"
import IconChevronUpRight from "../../Icon/IconChevronUpRight"

export default function InscriptionsHome() {
  return (
    <div className={"InscriptionsHome"}>
      <main className={"helpers-container"}>
        <header className={"InscriptionsHome-logo"}>
          <span className={"InscriptionsHome-logoDesktop"}>
            <Logo size={"l"}/>
          </span>
          <span className={"InscriptionsHome-logoMobile"}>
            <Logo size={"m"}/>
          </span>
        </header>
        <h1 className={"InscriptionsHome-title tag-h1"}>Inscription en ligne</h1>
        <h2 className={"InscriptionsHome-subtitle tag-h2 is-bold"}>Prêt à rejoindre HETIC ?</h2>
        <div className={"InscriptionsHome-infoMessage"}>
          <p className={"tag-p is-bold is-l"}>
            Avant de poursuivre votre inscription, assurez-vous d’avoir soumis votre candidature !
          </p>
        </div>
        <div className={"InscriptionsHome-parcours"}>
          <div className={"InscriptionsHome-parcoursItem"}>
            <div className={"InscriptionsHome-parcoursItemHeader"}>
              <p className={"InscriptionsHome-parcoursItemTitle tag-p is-bold is-l"}>
                Vous n’avez pas encore candidaté ?
              </p>
              <p className={"InscriptionsHome-parcoursItemText tag-p"}>
                L’inscription à HETIC est réservée aux candidats admis. Déposez votre candidature pour être éligible à l’inscription.
              </p>
            </div>
            <a
              aria-label={"Déposer ma candidature"}
              className={"InscriptionsHome-parcoursItemBtn tag-button"}
              href={"https://candidature.hetic.net"}
              title={"Déposer ma candidature"}
            >
              Déposer ma candidature
              <IconChevronUpRight/>
            </a>
          </div>
          <div className={"InscriptionsHome-line"}></div>
          <div className={"InscriptionsHome-parcoursItem"}>
            <div className={"InscriptionsHome-parcoursItemHeader"}>
              <p className={"InscriptionsHome-parcoursItemTitle tag-p is-bold is-l"}>
                Vous avez déjà soumis votre candidature ?
              </p>
              <p className={"InscriptionsHome-parcoursItemText tag-p"}>
                Si votre dossier de candidature a été validé, vous recevrez un mail avec les indications pour poursuivre votre inscription.
              </p>
            </div>
            <a
              aria-label={"Déposer ma candidature"}
              className={"InscriptionsHome-parcoursItemBtn tag-button"}
              href={"mailto:admissions@hetic.fr"}
              title={"Déposer ma candidature"}
            >
              Contacter l’admission
              <IconChevronUpRight/>
            </a>
          </div>
        </div>
      </main>
    </div>
  )
}