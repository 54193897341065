import "./CandidaturesSingleLayout.scss"

import {useEffect, useState} from "react"

import Logo from "../../../Logo/Logo"
import CandidaturesSingleLayoutSidebar from "./Sidebar/CandidaturesSingleLayoutSidebar"
import CandidaturesSingleLayoutMobileNavigation from "./MobileNavigation/CandidaturesSingleLayoutMobileNavigation"
import {getAnneeLabel, getSpecialisationLabel} from "../../helpers"
import {candidatureSessionAsMMMMYYYY} from "../helpers";

export default function CandidaturesSingleLayout(
  {
    children,
    candidature,
    currentEtape,
    onEtapeChange,
  }
) {
  const [specialisation, setSpecialisation] = useState("")
  const [annee, setAnnee] = useState("")
  const [session, setSession] = useState("")

  useEffect(() => {
    setSpecialisation(getSpecialisationLabel(candidature.Specialisation))
    setAnnee(getAnneeLabel(candidature.Annee))
    setSession(candidatureSessionAsMMMMYYYY(candidature))
  }, [])

  return (
    <div className={"CandidaturesSingleLayout"}>
      <aside className={"CandidaturesSingleLayout-sidebar"}>
        <div className={"CandidaturesSingleLayout-sidebarHeader"}>
          <Logo size={"m"}/>
        </div>
        <div className={"CandidaturesSingleLayout-sidebarContent"}>
          <CandidaturesSingleLayoutSidebar
            candidature={candidature}
            currentEtape={currentEtape}
            onEtapeChange={onEtapeChange}
          />
        </div>
      </aside>
      <aside className={"CandidaturesSingleLayout-mobileNavigation"}>
        <CandidaturesSingleLayoutMobileNavigation
          candidature={candidature}
          currentEtape={currentEtape}
          onEtapeChange={onEtapeChange}
        />
      </aside>
      <div className={"CandidaturesSingleLayout-content"}>
        <div className={"CandidaturesSingleLayout-contentHeader"}>
          <h1 className={"tag-h2"}>Candidature en ligne</h1>
          <p className={"tag-p is-s"}>
            {`${specialisation} / ${annee} / ${session}`}
          </p>
        </div>
        <div className={"CandidaturesSingleLayout-contentForm"}>
          {children}
        </div>
      </div>
    </div>
  )
}