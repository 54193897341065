import "./CandidaturesSingleAlternance.scss"

import {useState} from "react"

import FormInputRadioGroup from "../../../Form/InputRadio/Group/FormInputRadioGroup"
import FormInputRadio from "../../../Form/InputRadio/FormInputRadio"
import {scrollToFirstError} from "../../helpers"

export default function CandidaturesSingleAlternance({Alternance, onAlternanceChange}) {
  const [JaiDejaTrouveUneEntreprise, setJaiDejaTrouveUneEntreprise] = useState(Alternance.JaiDejaTrouveUneEntreprise)
  const [
    JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature,
    setJenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature,
  ] = useState(Alternance.JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature)
  const [
    SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise,
    setSavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise,
  ] = useState(Alternance.SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise)

  const submit = (event) => {
    event.preventDefault()

    if(!event.target.checkValidity()) {
      scrollToFirstError(event.target)
      return
    }

    onAlternanceChange({
      JaiDejaTrouveUneEntreprise,
      JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature,
      SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise,
    })
  }

  return (
    <form className={"CandidaturesSingleAlternance"} onSubmit={submit} noValidate>
      <div className={"helpers-formBody"}>
        <FormInputRadioGroup
          label={"Avez-vous déjà trouvé une entreprise ?"}
          required={true}
        >
          <FormInputRadio
            checked={JaiDejaTrouveUneEntreprise}
            id={"JaiDejaTrouveUneEntrepriseOui"}
            label={"Oui"}
            name={"JaiDejaTrouveUneEntreprise"}
            onChange={() => setJaiDejaTrouveUneEntreprise(true)}
            required={true}
            value={"Oui"}
          />
          <FormInputRadio
            checked={JaiDejaTrouveUneEntreprise === false}
            id={"JaiDejaTrouveUneEntrepriseNon"}
            label={"Non"}
            name={"JaiDejaTrouveUneEntreprise"}
            onChange={() => setJaiDejaTrouveUneEntreprise(false)}
            required={true}
            value={"Non"}
          />
        </FormInputRadioGroup>
        <FormInputRadioGroup
          label={"Savez-vous que vous pouvez réaliser une année d’alternance en vous acquittant des frais de scolarité même si vous n’avez pas d’entreprise ?"}
          required={true}
        >
          <FormInputRadio
            checked={SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise}
            id={"SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentrepriseOui"}
            label={"Oui"}
            name={"SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise"}
            onChange={() => setSavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise(true)}
            required={true}
            value={"Oui"}
          />
          <FormInputRadio
            checked={!SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise}
            id={"SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentrepriseNon"}
            label={"Non"}
            name={"SavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise"}
            onChange={() => setSavezVousQueVousPouvezRealiserUneAnneeDalternanceEnVousAcquittantDesFraisDeScolariteMemeSiVousNavezPasDentreprise(false)}
            required={true}
            value={"Non"}
          />
        </FormInputRadioGroup>
        <FormInputRadioGroup
          label={"Dans le cas où vous n’auriez pas trouvé d’alternance à la rentrée, envisageriez-vous d’auto-financer votre formation ?"}
          required={true}
        >
          <FormInputRadio
            checked={JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature}
            id={"JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidatureOui"}
            label={"Oui"}
            name={"JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature"}
            onChange={() => setJenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature(true)}
            required={true}
            value={"Oui"}
          />
          <FormInputRadio
            checked={!JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature}
            id={"JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidatureNon"}
            label={"Non"}
            name={"JenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature"}
            onChange={() => setJenvisageDeFinancerMaFormationMoiMemeSiAucuneEntrepriseNeRetientMaCandidature(false)}
            required={true}
            value={"Non"}
          />
        </FormInputRadioGroup>
      </div>
      <div className="helpers-formFooter">
        <button
          aria-label={"Continuer"}
          className={"helpers-buttonSubmit tag-button"}
          type={"submit"}
          title={"Continuer"}
        >
          Continuer
        </button>
      </div>
    </form>
  )
}